<template>
    <div>
        <page-title :heading="$t('accounting.lang_stornos')" :icon="icon"
                    :subheading="$t('accounting.lang_stornos')"></page-title>
        <div class="app-main__inner">
            <invoice-cancellation-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import InvoiceCancellationComponent
        from "../../../../components/analytics/warewanlytics/InvoiceCancellation/InvoiceCancellationComponent";
    export default {
        name: "index",
        components: {InvoiceCancellationComponent, PageTitle},
        data: () => ({
            icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
        })
    }
</script>

<style scoped>

</style>